import React from "react"
import Layout from "../layout/layout"

export default ()=>{return(
    <Layout title="Page not found">
        <h1>Error, <br/>Sorry, You are at wrong place...! </h1>
    </Layout>
)

}
